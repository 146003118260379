import React, { useRef } from "react";

const WyborZamowien = ({ data, handleWybrane, nextStep }) => {
    const inputZamowienia = useRef('');

    const checkEnter = (e) => {
        if(e.key === 'Enter') {
            const wybraneArray = inputZamowienia.current.value.split(',');
            const wybraneArrayInts = wybraneArray.map(Number);
            handleWybrane(wybraneArrayInts);
            nextStep(3);
        }
    }

    console.log(data);

    return (
        <>
            <header>
                <h4>Wprowadź numery zamówień</h4>
            </header>
            <div className="stanowisko">
                <input autoFocus className="stanowisko_input" ref={inputZamowienia} onKeyPress={checkEnter} type="text" />
            </div>
        </>
    )
}

export default WyborZamowien;
const Rodzaj = ({nextStep, handleRodzaj}) => {
    const handleChoose = (val) => {
        nextStep(1);
        handleRodzaj(val);
    }

    return (
        <>
            <header>
                <h4>Wybierz rodzaj pracy</h4>
            </header>
            <div className="buttons">
                <div onClick={() => handleChoose('kompletacja')}>Kompletacja - IdoSell</div>
                {/* <div onClick={() => handleChoose('api')}>Kompletacja - API</div> */}
                <div onClick={() => handleChoose('pakowanie')}>Pakowanie</div>
                <div onClick={() => handleChoose('wybrane zam.')}>Wybrane zamówienia</div>
            </div>
        </>
    )
}

export default Rodzaj;
import {useState, useRef} from 'react';

import { API_URL } from '../utils/api';

const Stanowisko = ({nextStep, handleStanowisko, rodzaj}) => {
    const inputStanowisko = useRef('');
    const [warning, setWarning] = useState(false);

    const handleChoose = (i) => {
        if(rodzaj === 'kompletacja' || rodzaj === 'api') {
            handleStanowisko(i);
            nextStep(1);
        } else if(rodzaj === 'wybrane zam.') {
            handleStanowisko(i);
            nextStep(1);
        } else {
            handleStanowisko(i);
            nextStep(5);
        }
    }

    const checkEnter = (e) => {
        if(e.key === 'Enter') {
            const checkPosition = async () => {
                const response = await fetch(`${API_URL}/auth/getPosition`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    mode: 'cors',
                    credentials: 'include',
                    body: JSON.stringify({
                        position: e.target.value
                    })
                });
                const json = await response.json();
                if(response.status === 404) {
                    setWarning(true);
                    setTimeout(() => {
                        setWarning(false);
                    }, 1300);
                } else {
                    if(json.status === 'ok') {
                        handleChoose(e.target.value);
                    } else {
                        setWarning(true);
                        setTimeout(() => {
                            setWarning(false);
                        }, 1300);
                    }
                }
            }
            checkPosition();
        }
    }

    return (
        <>
            {warning ? (
                <div className="warning">
                    <div>
                        Zeskanowany kod jest błędny
                    </div>
                </div>
            ) : null}
            <header>
                <h4>Zeskanuj kod wózka</h4>
            </header>
            <div className="stanowisko">
                <input autoFocus className="stanowisko_input" ref={inputStanowisko} onKeyPress={checkEnter} type="text" />
            </div>
        </>
    )
}

export default Stanowisko;
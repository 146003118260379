const Kurier = ({nextStep, handleKurier, data, api}) => {
    let couriers = data.map(item => item[1].courier.courierName);
    let uniqueCouriers = [...new Set(couriers)];
    console.log(uniqueCouriers);

    const handleChoose = (val) => {
        if(val === 'showroom') {
            nextStep(3);
        } else {
            nextStep(1);
        }
        handleKurier(val);
    }

    return (
        <>
            <header>
                <h4>Wybierz kuriera</h4>
            </header>
            <div className="buttons">
                {uniqueCouriers ? uniqueCouriers.map(item => (
                    <div key={item} onClick={() => handleChoose(item)}>{item}</div>
                )) : 'brak kurierów'}
                {!api && <div key="showroom" onClick={() => handleChoose('showroom')}>showroom</div>}
            </div>
        </>
    )
}

export default Kurier;
import { Routes, Route } from 'react-router-dom';
import AuthProvider from './context/authProvider';
import Home from './pages/Home';
import Login from './pages/Login';
import Error404 from './pages/404';
import ProtectedRoute from './components/ProtectedRoute';

const App = () => {
    return (
        <AuthProvider>
            <Routes>
                <Route path="/" exact element={<ProtectedRoute><Home /></ProtectedRoute>} />
                {/* <Route path="/" exact element={<Home />} /> */}
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Error404 />} />
            </Routes>
        </AuthProvider>
    )
};

export default App;
import React, { useState, useRef, useContext } from 'react';

import useSWR from 'swr';

import { API2_URL } from '../utils/api';
import { AuthContext } from '../context/authContext';

import CloseIcon from '../static/close.svg';

const ListaPakowaneShowroom = ({nextStep, data, kurier, rodzaj, lokalizacja, zamowienia, stanowisko, handleSetSuccesList, successList, wybraneZamowienia }) => {
    const inputEl = useRef('');
    let productIds = [];
    const [verifyId, setVerifyId] = useState(null);
    const [prodIds, setProdIds] = useState(null);
    const [verifyCount, setVerifyCount] = useState(0);
    const [success, setSuccess] = useState(false);
    const [warning, setWarning] = useState(false);
    const [faultCode, setFaultCode] = useState(null);
    const [showSort, setShowSort] = useState(false);
    const [sortType, setSortType] = useState('Lokalizacja (domyślnie)');

    const { user } = useContext(AuthContext);

    const documentsFromShowroomFetcher = async (url) => {
        const res = await fetch(`${API2_URL}${url}`);
        const { msg, documents } = await res.json();
        if(msg === 'success') {
            return documents;
        }
        if(msg === 'error') {
            throw 'Error'
        }
        return;
    }

    const { data: listPacked, error, mutate } = useSWR(`/stocks/getDocumentsFromShowroom`, documentsFromShowroomFetcher, {
        // revalidateOnFocus: false,
        // refreshInterval: 30000
    });


    let sizesOrder = ['xs', 's', 'm', 'l', 'xl', 'onesize'];

    if(listPacked) {
        switch(sortType) {
            case 'Alfabetycznie (A-Z)':
                listPacked.sort((a,b) => {
                    if(a.products[0]?.name < b.products[0]?.name) 
                        return -1;
                    if(a.products[0]?.name > b.products[0]?.name)
                        return 1
                    return 0;
                });
                break;
            case 'Alfabetycznie (Z-A)':
                listPacked.sort((b,a) => {
                    if(a.products[0]?.name < b.products[0]?.name) 
                        return -1;
                    if(a.products[0]?.name > b.products[0]?.name)
                        return 1
                    return 0;
                });
                break;
            case 'Rozmiar (XS-XL)':
                listPacked.sort((a,b) => {
                    return sizesOrder.indexOf(a.products[0]?.sizename) - sizesOrder.indexOf(b.products[0]?.sizename);
                });
                break;
            case 'Rozmiar (XL-XS)':
                listPacked.sort((b,a) => {
                    return sizesOrder.indexOf(a.products[0]?.sizename) - sizesOrder.indexOf(b.products[0].sizename);
                });
                break;
            case 'Lokalizacja (domyślnie)':
            default:
                listPacked.sort((a,b) => {
                    if(a.products[0]?.location < b.products[0]?.location) 
                        return -1;
                    if(a.products[0]?.location > b.products[0]?.location)
                        return 1
                    return 0;
                });
        }
    }

    const handleVerify = (documentId) => {
        setVerifyId(documentId);
        const test = listPacked.filter(item => item.documentId === documentId);
        test.forEach((el,i) => {
            el.products.forEach((el2, i2) => {
                productIds.push(el2.codeProducer);
            })
        });
        const count = productIds.length;
        setProdIds(productIds);
        setVerifyCount(count);
    }

    const handleVerifyEnd = () => {
        setVerifyId(null);
        productIds = [];
    }

    const handleVerifySubmit = () => {
        console.log(inputEl.current.value);
        let arr = [...prodIds];
        let index = arr.indexOf(inputEl.current.value);
        if(index !== -1) {
            arr.splice(index,1);
            setProdIds(arr);
            inputEl.current.value = '';
            inputEl.current.focus();
            if(verifyCount - 1 === 0) {
                console.log('ostatni');
                setVerifyCount(verifyCount - 1);
                handleSetSuccesList([...successList, verifyId]);
                handleSuccess();
            } else {
                console.log('nie ostatni');
                setVerifyCount(verifyCount - 1);
            }
        } else {
            inputEl.current.value = '';
            inputEl.current.focus();
            setWarning(true);
            setTimeout(() => {
                setWarning(false);
            }, 1300);
        }
    }

    const handleSuccess = async () => {
        const response = await fetch(`${API2_URL}/stocks/closeShowroomDocument`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify({
                documentId: verifyId
            })
        })
        const { msg, msgText } = await response.json();
        if(msg === 'success') {
            setSuccess(true);
            setVerifyCount(0);
            setVerifyId(null);
            productIds = [];
            setProdIds(null);
            setTimeout(() => {
                setSuccess(false);
            }, 1000);
        } else {
            setFaultCode(msgText);
            setTimeout(() => {
                setFaultCode(null);
            }, 3000);
        }
    }

    const checkEnter = (e) => {
        if(e.key === 'Enter') {
            handleVerifySubmit();
        }
    }

    const handleSortChange = (e) => {
        setSortType(e.target.textContent);
        setShowSort(false);
    }

    const handleRemove = async (e, id) => {
        e.preventDefault();
        if(window.confirm("Czy chcesz na pewno usunąć dokument z bazy?") === true) {
            const response = await fetch(`${API2_URL}/stocks/removeShowroomDocument`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                mode: 'cors',
                credentials: 'include',
                body: JSON.stringify({
                    documentId: id
                })
            })
            const { msg } = await response.json();
            if(msg === 'success') {
                setVerifyCount(0);
                setVerifyId(null);
                productIds = [];
                setProdIds(null);
                mutate();
            }
        }
    }

    return (
        <>
            {success && !warning && !faultCode ? (
                <div className="success">
                    <div>
                        Weryfikacja zakończona powodzeniem
                    </div>
                </div>
            ) : null}
            {warning ? (
                <div className="warning">
                    <div>
                        Zeskanowany kod jest błędny
                    </div>
                </div>
            ) : null}
            {faultCode ? (
                <div className="warning">
                    <div>
                        API: {faultCode}
                    </div>
                </div>
            ) : null}
            {error ? (
                <div className="warning">
                    <div>
                        Błąd API: {error}
                    </div>
                </div>
            ) : null}
            <header>
                <h4>Zamówienia na SHOWROOM</h4>
            </header>

            {showSort && (<div className="sortingChoose">
                <h4>Sortuj według:</h4>
                <ul>
                    <li onClick={(e) => handleSortChange(e)}>Lokalizacja (domyślnie)</li>
                    <li onClick={(e) => handleSortChange(e)}>Alfabetycznie (A-Z)</li>
                    <li onClick={(e) => handleSortChange(e)}>Alfabetycznie (Z-A)</li>
                    <li onClick={(e) => handleSortChange(e)}>Rozmiar (XS-XL)</li>
                    <li onClick={(e) => handleSortChange(e)}>Rozmiar (XL-XS)</li>
                </ul>
            </div>)}

            <div className="sorting">
                <button className="sortingSwtich" onClick={() => setShowSort(!showSort)}>Sortowanie <span>{sortType}</span></button>
            </div>

            <div className="lista showroom">
                {listPacked ? listPacked.map(item => (
                    <React.Fragment key={item.documentId}>
                    { successList.indexOf(item.documentId) === -1 ? (
                        <React.Fragment key={item.documentId}>
                            <div>
                                <button className="order_delete" onClick={(e) => handleRemove(e, item.documentId)}><img src={CloseIcon} alt="Usuń"/></button>
                                <div onClick={() => handleVerify(item.documentId)}>
                                    <div className="product_info">{item.products?.map(product => (
                                        <div key={product.id + product.location + product.size + product.quantity}>
                                            <div><span className={product.quantity > 1 ? 'red' : ''}>{product.quantity}x</span> {product.name}</div>
                                            <div className="product_size2">{product.sizename}<br />{product.location}</div>
                                            <div><img src={product.icon} alt={product.name}/></div>
                                        </div>
                                    ))}</div>
                                    <div className="order_note">Dokument nr {item.documentId}</div>
                                </div>
                                <div className="product_verify" onClick={() => handleVerify(item.documentId)}>Weryfikuj</div>
                            </div>
                        </React.Fragment>
                    ) : null}
                    </React.Fragment>
                )) : 'brak zamówień'}
            </div>
            {verifyId ? (
                <div className="popup">
                    <h2>Zeskanuj towary w zamówieniu</h2>
                    <span className="close_popup" onClick={handleVerifyEnd}><div></div></span>
                    <div className="code_text">Kod towaru</div>
                    <div>
                        <input autoFocus className="code_input" ref={inputEl} onKeyPress={checkEnter} type="text" />
                        <button className="code_button" onClick={handleVerifySubmit}>Zatwierdź</button>
                    </div>
                    <div className="code_info">Zeskanuj kody towarów w zamówieniu, aby zweryfikować towary</div>
                    {listPacked.filter(item => item.documentId === verifyId).map(filteredId => (
                        <div className="popup_products" key={filteredId.documentId}>
                            {filteredId.products?.map(product => (
                                <div key={product.codeProducer} className={`popup_product ${prodIds.indexOf(product.codeProducer) === -1 ? 'done' : 'not-done'}`}>
                                    <div>
                                        {product.name}
                                    </div>
                                    <div>
                                        {product.size}
                                    </div>
                                    <div>
                                        {product.quantity} szt.
                                    </div>
                                    <div>
                                        <img src={product.icon} alt={product.name} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ) : null}
        </>
    )
}

export default ListaPakowaneShowroom;
import React, { useState, useEffect, useContext } from 'react';
import Barcode from 'react-barcode';

import CloseIcon from '../static/close.svg';

import { API_URL } from '../utils/api';
import { AuthContext } from '../context/authContext';

const ListaSpakowane = ({stanowisko}) => {
    const [spakowane, setSpakowane] = useState([]);
    const { user } = useContext(AuthContext);
    const fetchData = async () => {
        // const response = await fetch(`${API_URL}/orders/getOrders?employee=${user.id}&position=${stanowisko}`, {
        const response = await fetch(`${API_URL}/orders/getOrders?position=${stanowisko}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            credentials: 'include',
        });
        const json = await response.json();
        //const arr = json.data.filter(item => item.position === stanowisko.toString()).map(item => JSON.parse(item.json.replace(/&quot;/g, '"')));
        const arr = json;
        setSpakowane(arr);
    }
    useEffect(() => {
        fetchData();

        const interval = setInterval(() => {
            fetchData();
        }, 3000);

        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    const handleRemove = async (sn) => {
        if(window.confirm(`Czy na pewno usunąć zamówienie ${sn} ze spakowanych?`)) {
            removeOrder(sn);
        }
    }

    const removeOrder = async (sn) => {
        const response = await fetch(`${API_URL}/orders/deleteOrder`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify({sn})
        })
        const json = await response.json();

        if(json.status === 'deleted') {
            fetchData();
        }
    }

    return (
        <>
            <header>
                <h4>Zamówienia o statusie "spakowane"</h4>
            </header>
            <div className="lista_header spakowane">
                <div>
                    <div>Zamówienie</div>
                    <div>Kurier</div>
                    <div>Nazwa</div>
                    <div>Rozmiar</div>
                    <div>Ilość</div>
                    <div>Zdjęcie</div>
                </div>
            </div>
            <div className="lista spakowane">
                {spakowane ? spakowane.map(item => (
                    <React.Fragment key={item.sn}>
                        <div>
                            <button className="order_delete" onClick={() => handleRemove(item.sn)}><img src={CloseIcon} alt="Usuń ze spakowanych"/></button>
                            <div className="order_sn"><Barcode value={item.sn.toString()}/></div>
                            <div className="product_info">
                                <div>
                                    <div className="order_courier">{item?.courierName}</div>
                                    <div className="product_name">{item?.products?.length > 1 ? item?.products?.map(product => (
                                        <div key={product.id}>{product.name}</div>
                                    )) : (item?.products[0]?.name)}</div>
                                    <div className="product_size">{item?.products?.length > 1 ? item?.products?.map(product => (
                                        <div key={product.id}>{product.size}</div>
                                    )) : (item?.products[0]?.size)}</div>
                                    <div className="product_quantity">{item?.products?.length > 1 ? item?.products?.map(product => (
                                        <div key={product.id}>{product.quantity}</div>
                                    )) : (item?.products[0]?.quantity)}</div>
                                    <div className="product_icon">{item?.products?.length > 1 ? item?.products?.map(product => (
                                        <div className="multiple_images" key={product.id}><img src={product.icon} alt={product.name}/></div>
                                    )) : (<img src={item?.products[0]?.icon} alt={item?.products[0]?.name}/>)}</div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )) : 'brak zamówień'}
            </div>
        </>
    )
}

export default ListaSpakowane;
import { useContext, useRef } from "react";
import { AuthContext } from "../context/authContext";
import './Login.css';

const Login = () => {
    const qrCodeInput = useRef(null);
    const { loginLoading, loginError, backToLogin, onLogin } = useContext(AuthContext);

    const handleLoginSubmit = () => {
        onLogin(qrCodeInput.current.value);
    }

    const checkEnter = (e) => {
        if(e.key === 'Enter') {
            handleLoginSubmit();
        }
    }

    return (
        loginLoading ? (<div></div>) : (
            <div className={loginError ? 'LoginPage LoginError' : 'LoginPage'}>
                {loginError ? (
                    <div>
                        <div>{loginError.error}</div>
                        <button type="button" onClick={() => backToLogin()}>Powrót</button>
                    </div>
                ) : (
                    <div>
                        <img src="https://lou.pl/data/gfx/mask/pol/logo_4_big.png" alt="Lou logo" />
                        <div className="LoginHeader">Zaloguj się kodem QR</div>
                        <input autoFocus type="password" ref={qrCodeInput} onKeyPress={checkEnter} />
                        {/* <button type="button" onClick={() => onLogin(qrCodeInput.current.value)}>Zaloguj</button> */}
                    </div>
                )}
            </div>
        )
    )
};

export default Login;
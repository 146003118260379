import React, { useState, useRef, useContext } from 'react';
import moment from 'moment';
import 'moment/locale/pl';

import { API_URL } from '../utils/api';
import { AuthContext } from '../context/authContext';

const ListaPakowane = ({nextStep, data, kurier, rodzaj, lokalizacja, zamowienia, stanowisko, handleSetSuccesList, successList, wybraneZamowienia }) => {
    const inputEl = useRef('');
    let productIds = [];
    const [verifyId, setVerifyId] = useState(null);
    const [prodIds, setProdIds] = useState(null);
    const [verifyCount, setVerifyCount] = useState(0);
    const [success, setSuccess] = useState(false);
    const [warning, setWarning] = useState(false);
    const [faultCode, setFaultCode] = useState(null);
    const [showSort, setShowSort] = useState(false);
    const [sortType, setSortType] = useState('Lokalizacja (domyślnie)');

    const { user } = useContext(AuthContext);

    let listPacked;
    if(rodzaj === 'wybrane zam.') {
        listPacked = data.filter(order => wybraneZamowienia.includes(order[1].sn) && successList.indexOf(order[1].sn) === -1);
    } else if(zamowienia === 'pojedyncze') {
        listPacked = data.filter(order => order[1].courier.courierName === kurier && order[1].products[0].location && order[1].products[0].location.toUpperCase().split('-')[0] === lokalizacja && successList.indexOf(order[1].sn) === -1 && order[1].products.length === 1);
    } else if(zamowienia === 'wieloproduktowe') {
        listPacked = data.filter(order => order[1].courier.courierName === kurier && successList.indexOf(order[1].sn) === -1 && order[1].products.length > 1);
    } else {
        listPacked = data.filter(order => order[1].courier.courierName === kurier && successList.indexOf(order[1].sn) === -1);
    }

    let sizesOrder = ['xs', 's', 'm', 'l', 'xl', 'onesize'];

    switch(sortType) {
        case 'Alfabetycznie (A-Z)':
            listPacked.sort((a,b) => {
                if(a[1].products[0].name < b[1].products[0].name) 
                    return -1;
                if(a[1].products[0].name > b[1].products[0].name)
                    return 1
                return 0;
            });
            break;
        case 'Alfabetycznie (Z-A)':
            listPacked.sort((b,a) => {
                if(a[1].products[0].name < b[1].products[0].name) 
                    return -1;
                if(a[1].products[0].name > b[1].products[0].name)
                    return 1
                return 0;
            });
            break;
        case 'Rozmiar (XS-XL)':
            listPacked.sort((a,b) => {
                return sizesOrder.indexOf(a[1].products[0].size) - sizesOrder.indexOf(b[1].products[0].size);
            });
            break;
        case 'Rozmiar (XL-XS)':
            listPacked.sort((b,a) => {
                return sizesOrder.indexOf(a[1].products[0].size) - sizesOrder.indexOf(b[1].products[0].size);
            });
            break;
        case 'Przekroczony czas wysyłki':
            listPacked.sort((a,b) => {
                if(a[1].exceededEstimatedDate < b[1].exceededEstimatedDate)
                    return 1;
                if(a[1].exceededEstimatedDate > b[1].exceededEstimatedDate)
                    return -1
                return 0;
            });
            break;
        case 'Lokalizacja (domyślnie)':
        default:
            listPacked.sort((a,b) => {
                if(a[1].products[0].location < b[1].products[0].location) 
                    return -1;
                if(a[1].products[0].location > b[1].products[0].location)
                    return 1
                return 0;
            });
    }

    const handleVerify = (sn) => {
        console.log(sn);
        setVerifyId(sn);
        const test = listPacked.filter(item => item[1].sn === sn);
        test.forEach((el,i) => {
            el[1].products.forEach((el2, i2) => {
                productIds.push(el2.codeProducer);
            })
        });
        console.log(productIds);
        const count = productIds.length;
        console.log(count);
        setProdIds(productIds);
        setVerifyCount(count);
    }

    const handleVerifyEnd = () => {
        setVerifyId(null);
        productIds = [];
        console.log(productIds);
    }

    const handleVerifySubmit = () => {
        console.log(inputEl.current.value);
        let arr = [...prodIds];
        let index = arr.indexOf(inputEl.current.value);
        if(index !== -1) {
            arr.splice(index,1);
            setProdIds(arr);
            inputEl.current.value = '';
            inputEl.current.focus();
            if(verifyCount - 1 === 0) {
                console.log('ostatni');
                setVerifyCount(verifyCount - 1);
                handleSetSuccesList([...successList, verifyId]);
                handleSuccess();
            } else {
                console.log('nie ostatni');
                setVerifyCount(verifyCount - 1);
            }
        } else {
            inputEl.current.value = '';
            inputEl.current.focus();
            setWarning(true);
            setTimeout(() => {
                setWarning(false);
            }, 1300);
        }
    }

    const handleSuccess = async () => {
        const response = await fetch(`${API_URL}/iai/updateOrder?id=${verifyId}&status=packed_ready`);
        const json = await response.json();

        if(json.ordersResults[0].faultCode !== 0) {
            setFaultCode(json.ordersResults[0].faultString);
            setTimeout(() => {
                setFaultCode(null);
            }, 3000);
        } else {
            setSuccess(true);
            const prodId = verifyId;
            const test = listPacked.filter(item => item[1].sn === prodId);
            // const test2 = JSON.stringify(test[0][1]);
            const resp3 = await fetch(`${API_URL}/orders/newOrder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                mode: 'cors',
                credentials: 'include',
                body: JSON.stringify({
                    sn: test[0][1].sn,
                    products: test[0][1].products,
                    courierName: test[0][1].courier.courierName,
                    employeeId: user.id,
                    positionId: stanowisko
                })
            })
            const json3 = await resp3.json();
            console.log(json3);
            setVerifyCount(0);
            setVerifyId(null);
            productIds = [];
            setProdIds(null);
            setTimeout(() => {
                setSuccess(false);
            }, 1000);
        }
    }

    const checkEnter = (e) => {
        if(e.key === 'Enter') {
            handleVerifySubmit();
        }
    }

    const handleSortChange = (e) => {
        setSortType(e.target.textContent);
        setShowSort(false);
    }

    const diffDate = (date) => {
        let today = moment().format('YYYY-MM-DD');
        let estimated = moment(date);
        return estimated.diff(today, 'days');
    }

    return (
        <>
            {success && !warning && !faultCode ? (
                <div className="success">
                    <div>
                        Weryfikacja zakończona powodzeniem
                    </div>
                </div>
            ) : null}
            {warning ? (
                <div className="warning">
                    <div>
                        Zeskanowany kod jest błędny
                    </div>
                </div>
            ) : null}
            {faultCode ? (
                <div className="warning">
                    <div>
                        Błąd API: {faultCode}
                    </div>
                </div>
            ) : null}
            <header>
                <h4>Zamówienia o statusie "pakowane"</h4>
            </header>

            {showSort && (<div className="sortingChoose">
                <h4>Sortuj według:</h4>
                <ul>
                    <li onClick={(e) => handleSortChange(e)}>Lokalizacja (domyślnie)</li>
                    <li onClick={(e) => handleSortChange(e)}>Alfabetycznie (A-Z)</li>
                    <li onClick={(e) => handleSortChange(e)}>Alfabetycznie (Z-A)</li>
                    <li onClick={(e) => handleSortChange(e)}>Rozmiar (XS-XL)</li>
                    <li onClick={(e) => handleSortChange(e)}>Rozmiar (XL-XS)</li>
                    <li onClick={(e) => handleSortChange(e)}>Przekroczony czas wysyłki</li>
                </ul>
            </div>)}

            <div className="sorting">
                <button className="sortingSwtich" onClick={() => setShowSort(!showSort)}>Sortowanie <span>{sortType}</span></button>
            </div>

            <div className="lista">
                {listPacked ? listPacked.map(item => (
                    <React.Fragment key={item[1].sn}>
                    { successList.indexOf(item[1].sn) === -1 ? (
                        <React.Fragment key={item[1].sn}>
                            <div onClick={() => handleVerify(item[1].sn)} className={item[1].exceededEstimatedDate ? 'exceeded': (item[1].courier.courierName.indexOf('DHL') !== -1 || item[1].courier.courierName.indexOf('UPS') !== -1) ? 'dhlups' : ''}>
                                {item[1].exceededEstimatedDate && (<div className="exceededInfo">Planowany czas wysyłki: <span>{item[1].estimatedDate}</span></div>)}
                                {diffDate(item[1].estimatedDate.split(' ')[0]) > 0 && (<div className="daysDiff"><img src="https://lou.pl/data/include/cms/fast-delivery.svg?123" alt="planowana wysylka" />+{diffDate(item[1].estimatedDate.split(' ')[0])}</div>)}
                                <div className="product_info">{item[1].products.map(product => (
                                    <div key={product.id + product.location + product.size + product.quantity}>
                                        <div><span className={product.quantity > 1 ? 'red' : ''}>{product.quantity}x</span> {product.name}</div>
                                        <div className="product_size2">{product.size}<br />{product.location}</div>
                                        <div><img src={product.icon} alt={product.name}/></div>
                                    </div>
                                ))}</div>
                                {item[1].note && (<div className="order_note">{item[1].note}</div>)}
                            </div>
                            <div className="product_verify" onClick={() => handleVerify(item[1].sn)}>Weryfikuj</div>
                        </React.Fragment>
                    ) : null}
                    </React.Fragment>
                )) : 'brak zamówień'}
            </div>
            {verifyId ? (
                <div className="popup">
                    <h2>Zeskanuj towary w zamówieniu</h2>
                    <span className="close_popup" onClick={handleVerifyEnd}><div></div></span>
                    <div className="code_text">Kod towaru</div>
                    <div>
                        <input autoFocus className="code_input" ref={inputEl} onKeyPress={checkEnter} type="text" />
                        <button className="code_button" onClick={handleVerifySubmit}>Zatwierdź</button>
                    </div>
                    <div className="code_info">Zeskanuj kody towarów w zamówieniu, aby zweryfikować towary</div>
                    {listPacked.filter(item => item[1].sn === verifyId).map(filteredId => (
                        <div className="popup_products" key={filteredId.sn}>
                            {filteredId[1].products.map(product => (
                                <div key={product.codeProducer} className={`popup_product ${prodIds.indexOf(product.codeProducer) === -1 ? 'done' : 'not-done'}`}>
                                    <div>
                                        {product.name}
                                    </div>
                                    <div>
                                        {product.size}
                                    </div>
                                    <div>
                                        {product.quantity} szt.
                                    </div>
                                    <div>
                                        <img src={product.icon} alt={product.name} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ) : null}
        </>
    )
}

export default ListaPakowane;
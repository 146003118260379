const Zamowienia = ({nextStep, handleZamowienia, handleLokalizacja}) => {
    const handleChoose = (val) => {
        handleZamowienia(val);

        if(val === 'wieloproduktowe' || val === 'wszystkie') {
            handleLokalizacja('wszystkie');
            nextStep(2);
        } else {
            nextStep(1);
            handleLokalizacja(null);
        }
    }

    return (
        <>
            <header>
                <h4>Wybierz rodzaj zamówień</h4>
            </header>
            <div className="buttons">
                <div onClick={() => handleChoose('pojedyncze')}>Pojedyncze zamówienia</div>
                <div onClick={() => handleChoose('wieloproduktowe')}>Wieloproduktowe zamówienia</div>
                <div onClick={() => handleChoose('wszystkie')}>Wszystkie</div>
            </div>
        </>
    )
}

export default Zamowienia;
const Loading = () => {
    return (
        <>
            <header className="loading">
                <h4>Pobieranie danych...</h4>
            </header>
        </>
    )
}

export default Loading;
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { AuthContext } from './authContext';

import { API_URL } from '../utils/api';

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const [loginLoading, setLoginLoading] = useState(true);

  useEffect(() => {
    const getSession = async () => {
      const response = await fetch(`${API_URL}/auth/verifyToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        mode: 'cors',
        credentials: 'include',
      })

      const { error, user } = await response.json();

      if(!error) {
        setUser(user);
        setLoginLoading(false);

        navigate('/');
      } else {
        setLoginLoading(false);
        return;
      }
    }
    getSession();
    // eslint-disable-next-line
  }, []);

  const handleLogin = async (login) => {
      const response = await fetch(`${API_URL}/auth/getToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({
          qrcode: login
        })
      })

      const { error, user } = await response.json();
      if(!error) {
        setUser(user);

        navigate('/');
      } else {
        setLoginError({error: 'Błąd autoryzacji'});
      }
  }

  const handleLogout = async (mode) => {
    console.log(user);
    const response = await fetch(`${API_URL}/auth/logout?mode=${mode}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(user)
    });

    const { status } = await response.json();
    
    if(status === 'ok') {
      setUser(null);
      navigate('/login');
    } else {
      setLoginError({error: 'Błąd autoryzacji'});
    }
  }

  const handleBackToLogin = () => {
    setUser(null);
    setLoginError(null);
  }

  const value = {
      user,
      loginError,
      loginLoading,
      backToLogin: handleBackToLogin,
      onLogin: handleLogin,
      onLogout: handleLogout
  }

  return (
      <AuthContext.Provider value={value}>
          {children}
      </AuthContext.Provider>
  )
}

export default AuthProvider;
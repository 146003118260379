import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";

const ProtectedRoute = ({redirectPath = '/login', children}) => {
    const { user } = useContext(AuthContext);
    if (!user) {
        return <Navigate to={redirectPath} replace />;
    }

    return children;
}

export default ProtectedRoute;
